import "./App.scss";
import AOS from "aos";
import React, { useState, useEffect } from "react";
import moment from "moment";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/vendor/icofont/icofont.min.css";
import "./assets/vendor/boxicons/css/boxicons.min.css";
import "./assets/css/style.css";

import "aos/dist/aos.css";

import "react-modal-video/scss/modal-video.scss";
import ModalVideo from "react-modal-video";

import IndexNavbar from "./components/navbar";
import ContactForm from "./components/contact-form";
import Sermons from "./components/sermons";
import BackgroundVideo from "./components/background-video";

//APIs
import Collections from "./services/collections";
import collections from "./services/collections";

function App() {
  AOS.init();
  const [loading, setLoading] = useState(true);
  const [eventos, setEventos] = useState([]);
  const [teams, setTeams] = useState([]);
  const [videos, setVideos] = useState([]);
  const [quienesSomos, setQuienesSomos] = useState([]);
  const [pastores, setPastores] = useState(null);
  const [ministerios, setMinisterios] = useState([]);
  const [diezmoOfrendas, setDiezmoOfrendas] = useState(null);
  const [videoId, setVideoId] = useState(null);
  const [isVideoOpen, setVideoOpen] = useState(false);

  useEffect(() => {
    Promise.all([
      Collections.eventos(),
      Collections.teams(),
      Collections.videos(),
      Collections.pastores(),
      Collections.ministerios(),
      Collections.diezmoOfrendas(),
      collections.quienesSomos(),
    ])
      .then((data) => {
        setEventos(data[0]);
        setTeams(data[1]);
        setVideos(data[2]);
        setPastores(data[3]);
        setMinisterios(data[4]);
        setDiezmoOfrendas(data[5]);
        setQuienesSomos(data[6]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const openVideo = (videoId) => {
    setVideoId(videoId);
    setVideoOpen(true);
  };

  return (
    <div>
      <IndexNavbar />

      <BackgroundVideo />

      <main id="main">
        {/* <!-- ======= Conocenos ======= --> */}
        <section id="conocenos" className="why-us">
          <div className="container">
            <div className="section-title">
              <span>¿QUIÉNES SOMOS?</span>
              <h2>¿QUIÉNES SOMOS?</h2>
              {/* <p>Sit sint consectetur velit quisquam cupiditate impedit suscipit alias</p> */}
            </div>
            <div className="row">
              {quienesSomos.length > 0
                ? quienesSomos.map((item) => {
                    return (
                      <div
                        className="col-lg-4 mt-4 mt-lg-0"
                        data-aos="fade-up"
                        data-aos-delay={150}
                      >
                        <div className="box">
                          <h4>{item.Titulo}</h4>
                          {/* <h4>Repellat Nihil</h4> */}
                          <p>{item.Descripcion}</p>
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        </section>
        {/* <!-- ======= End of Conocenos ======= --> */}

        {/* <!-- ======= Pastores ======= --> */}
        <section id="pastores" className="about">
          <div className="container">
            <div className="section-title">
              <span>NUESTROS PASTORES</span>
              <h2>NUESTROS PASTORES</h2>
              {/* <p>Sit sint consectetur velit quisquam cupiditate impedit suscipit alias</p> */}
            </div>
            {pastores
              ? (() => {
                  let info = pastores;
                  return (
                    <div className="row">
                      <div
                        className="col-lg-6 order-1 order-lg-2"
                        data-aos="fade-left"
                      >
                        <img
                          src={info.Foto.url}
                          className="img-fluid"
                          alt="Pastor y su famila"
                        />
                      </div>
                      <div
                        className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content"
                        data-aos="fade-right"
                      >
                        <h3>{info.Titulo}</h3>
                        <p className="font-italic">{info.Sub_Titulo}</p>
                        {/* <ul>
                    <li><i className="icofont-check-circled" /> Ullamco laboris nisi ut aliquip ex ea commodo consequat.</li>
                    <li><i className="icofont-check-circled" /> Duis aute irure dolor in reprehenderit in voluptate velit.</li>
                    <li><i className="icofont-check-circled" /> Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate trideta storacalaperda mastiro dolore eu fugiat nulla pariatur.</li>
                  </ul> */}
                        <p>{info.Descripcion}</p>
                      </div>
                    </div>
                  );
                })()
              : null}
          </div>
        </section>

        {/* <!-- End of Pastores --> */}

        {/* <!-- ======= Cta Ofrendas Section ======= --> */}
        <section
          id="ofrendas"
          style={{
            background: `linear-gradient(rgba(2, 2, 2, 0.5), rgba(0, 0, 0, 0.5)), url(${
              diezmoOfrendas ? diezmoOfrendas.Foto.url : null
            }) fixed center center`,
          }}
          className="cta"
        >
          <div className="container" data-aos="zoom-in">
            {diezmoOfrendas
              ? (() => {
                  let info = diezmoOfrendas;
                  return (
                    <div className="text-center">
                      <h3>{info.Titulo}</h3>
                      <p>{info.Descripcion}</p>
                      <p className="font-italic">{info.Cita_Biblica}</p>
                      <p className="font-italic">{info.Referencia}</p>
                      <a
                        className="cta-btn"
                        href="https://lamies.churchcenter.com/giving"
                      >
                        Enviar Ofrenda/Diezmo
                      </a>
                    </div>
                  );
                })()
              : null}
          </div>
        </section>

        {/* <!-- End Cta Ofrendas Section --> */}

        {/* <!-- End Clients Section --> */}

        {/* <!-- ======= Eventos Section ======= --> */}
        <section id="eventos" className="services">
          <div className="container">
            {eventos.length > 0 ? (
              <div className="section-title">
                <span>Eventos</span>
                <h2>Eventos</h2>
                <p>
                  Forma parte de nosotros y conectate a través de nuestros
                  eventos
                </p>
              </div>
            ) : null}
            <div className="row">
              {eventos.length > 0
                ? eventos.slice(0, 5).map((item) => {
                    return (
                      <div
                        key={item.id}
                        className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
                        data-aos="fade-up"
                        data-aos-delay={150}
                      >
                        <div className="icon-box">
                          <img
                            src={item.foto ? item.foto.url : ""}
                            alt="Foto de Evento"
                          />
                          <h4>
                            <a href>{item.titulo}</a>
                          </h4>
                          <div className="events-meta">
                            <span>
                              <i
                                className="bx bx-calendar"
                                aria-hidden="true"
                              ></i>
                              {moment(item.fecha).format("MM-DD-YYYY")}
                            </span>
                            <span>
                              <i className="bx bx-time" aria-hidden="true"></i>
                              {moment(item.fecha + " " + item.hora).format(
                                "hh:mm a"
                              )}{" "}
                            </span>
                            <span>
                              <i className="bx bx-map" aria-hidden="true"></i>{" "}
                              {item.direccion}
                            </span>
                          </div>
                          <p>{item.descripcion}</p>
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        </section>

        {/* <!-- End Eventos Section --> */}

        {/* Sermon Section  */}
        <Sermons openVideo={openVideo} videos={videos} />
        <React.Fragment>
          <ModalVideo
            channel="youtube"
            autoplay={true}
            isOpen={isVideoOpen}
            videoId={videoId}
            onClose={() => setVideoOpen(false)}
          />
        </React.Fragment>
        {/* End Sermon Section  */}

        {/* <!-- ======= Pricing Section ======= --> */}
        <section id="ministerios" className="ministerios">
          <div className="container">
            <div className="section-title">
              <span>Ministerios</span>
              <h2>Ministerios</h2>
              <p>
                Nuestros ministerio estan para ayudarte a crecer en el Señor.
                Integrate!!
              </p>
            </div>
            <div className="row">
              {ministerios.length > 0
                ? ministerios.map((ministerio) => {
                    return (
                      <div
                        className="col-lg-4 col-md-6"
                        data-aos="zoom-in"
                        data-aos-delay={150}
                      >
                        <div className="box">
                          <img src={ministerio.Foto.url} />
                          <div className="content">
                            <h3>{ministerio.Titulo}</h3>
                            <div className="events-meta">
                              <span>
                                <i
                                  className="bx bx-calendar"
                                  aria-hidden="true"
                                ></i>
                                {ministerio.Dia}
                              </span>
                              <span>
                                <i
                                  className="bx bx-time"
                                  aria-hidden="true"
                                ></i>
                                {ministerio.Horario}
                              </span>
                              <span>
                                <i className="bx bx-map" aria-hidden="true"></i>
                                {ministerio.Lugar}
                              </span>
                            </div>
                            <div className="description">
                              <p>{ministerio.Descripcion}</p>
                            </div>
                          </div>
                          <div className="btn-wrap">
                            <a href="#" className="btn-buy">
                              Conocenos
                            </a>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        </section>

        {/* <!-- ======= Team Section ======= --> */}
        <section id="ministros" className="team">
          <div className="container">
            {teams.length > 0 ? (
              <div className="section-title">
                <span>Team</span>
                <h2>Team</h2>
                <p>
                  Nuestros Lideres Que Sirven a Dios en Varios Departamentos
                </p>
              </div>
            ) : null}
            <div className="row">
              {teams.length > 0
                ? teams.map((member) => {
                    return (
                      <div
                        key={member.id}
                        className="col-lg-4 col-md-6 d-flex align-items-stretch"
                        data-aos="zoom-in"
                      >
                        <div className="member">
                          <img src={member.photo ? member.photo.url : ""} alt />
                          <h4>{member.name}</h4>
                          <span>{member.role}</span>
                          <p>{member.bio}</p>
                          <div className="social">
                            <a href>
                              <i className="icofont-twitter" />
                            </a>
                            <a href>
                              <i className="icofont-facebook" />
                            </a>
                            <a href>
                              <i className="icofont-instagram" />
                            </a>
                            <a href>
                              <i className="icofont-linkedin" />
                            </a>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        </section>

        {/* <!-- End Team Section --> */}

        <ContactForm />
      </main>
      {/* <!-- End #main -->  */}

      {/* ======= Footer ======= */}
      <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-6">
                <div className="footer-info">
                  <h3>Iglesia La Mies</h3>
                  <p>
                    6515 Tezel Rd. San Antonio, Tx 78250
                    <br />
                    <br />
                    <strong>Phone:</strong>+1 210 992 2894
                    <br />
                    <strong>Email:</strong>webmaster@iglesialamies.org
                    <br />
                  </p>
                  <div className="social-links mt-3">
                    <a href="https://www.facebook.com/LaMiesAG/" className="facebook">
                      <i className="bx bxl-facebook" />
                    </a>
                    <a href="https://www.youtube.com/channel/UCTW61QBeCDGquI_78kngBNA" className="youtube">
                      <i className="bx bxl-youtube" />
                    </a>
                    {/* <a href="#" className="instagram">
                      <i className="bx bxl-instagram" />
                    </a>
                    <a href="#" className="google-plus">
                      <i className="bx bxl-skype" />
                    </a>
                    <a href="#" className="linkedin">
                      <i className="bx bxl-linkedin" />
                    </a> */}
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-2 col-md-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li><i className="bx bx-chevron-right" /> <a href="#">Home</a></li>
                  <li><i className="bx bx-chevron-right" /> <a href="#">About us</a></li>
                  <li><i className="bx bx-chevron-right" /> <a href="#">Services</a></li>
                  <li><i className="bx bx-chevron-right" /> <a href="#">Terms of service</a></li>
                  <li><i className="bx bx-chevron-right" /> <a href="#">Privacy policy</a></li>
                </ul>
              </div> */}
              {/* <div className="col-lg-2 col-md-6 footer-links">
                <h4>Our Services</h4>
                <ul>
                  <li><i className="bx bx-chevron-right" /> <a href="#">Web Design</a></li>
                  <li><i className="bx bx-chevron-right" /> <a href="#">Web Development</a></li>
                  <li><i className="bx bx-chevron-right" /> <a href="#">Product Management</a></li>
                  <li><i className="bx bx-chevron-right" /> <a href="#">Marketing</a></li>
                  <li><i className="bx bx-chevron-right" /> <a href="#">Graphic Design</a></li>
                </ul>
              </div> */}
              {/* <div className="col-lg-4 col-md-6 footer-newsletter">
                <h4>Our Newsletter</h4>
                <p>Tamen quem nulla quae legam multos aute sint culpa legam noster magna</p>
                <form action method="post">
                  <input type="email" name="email" /><input type="submit" defaultValue="Subscribe" />
                </form>
              </div> */}
            </div>
          </div>
        </div>
        <div className="container makeSmall ">
          <div className="copyright">
            © Copyright{" "}
            <strong>
              <span>Day</span>
            </strong>
            . All Rights Reserved
          </div>
          <div className="credits makeSmall">
            {/* All the links in the footer should remain intact. */}
            {/* You can delete the links only if you purchased the pro version. */}
            {/* Licensing information: https://bootstrapmade.com/license/ */}
            {/* Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/day-multipurpose-html-template-for-free/ */}
            Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
          </div>
        </div>
      </footer>
      {/* End Footer --*/}

      <div id="preloader" className={loading ? "" : "hidden"}></div>
    </div>
  );
}

export default App;
